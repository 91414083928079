import axios from 'axios'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import './style.css'

interface VacationModeState {
  vacationModeOn: 0 | 1
  header?: string
  message?: string
}

interface VacationModeRes {
  status: number
  data: {
    vacationModeOn: 0 | 1
    header: string
    message: string
  }
}

function EditVacationModeComponent() {
  const [vacationModeState, setVacationModeState] =
    useState<VacationModeState>()

  const vacationModeHeader = useFormInput('')
  const vacationModeMessage = useFormInput('')

  // TODO: figure out why i can't type shit or save it D:
  // useEffect(() => {
  //   console.log('ahhhhh')
  //   // if (vacationModeHeader || vacationModeMessage) {
  //   setVacationModeState((prev: any) => ({
  //     // vacationModeOn: prev?.vacationModeOn || 0,
  //     ...prev,
  //     header: vacationModeHeader.value,
  //     message: vacationModeMessage.value
  //   }))
  //   // }
  // }, [vacationModeHeader, vacationModeMessage])

  // retrieve existing Vacation Mode settings from db once
  useEffect(() => {
    async function getVacationMode() {
      const vacationModeRes: VacationModeRes = await axios.get(
        '/api/vacation-mode'
      )

      if (vacationModeRes.status === 200) {
        if (vacationModeRes.data?.vacationModeOn) {
          setVacationModeState({
            vacationModeOn: 1,
            header: vacationModeRes.data?.header,
            message: vacationModeRes.data?.message
          })
        } else {
          setVacationModeState({
            vacationModeOn: 0,
            header: vacationModeRes.data?.header,
            message: vacationModeRes.data?.message
          })
        }
        vacationModeHeader.setValue(vacationModeRes.data?.header || '')
        vacationModeMessage.setValue(vacationModeRes.data?.message || '')
      } else {
        throw new Error('Error getting vacation mode')
      }
    }

    getVacationMode()
  }, [])

  const handleSubmit = (e: Event) => {
    e.preventDefault()

    const payload = {
      vacationModeOn: vacationModeState?.vacationModeOn,
      header: vacationModeHeader.value,
      message: vacationModeMessage.value
    }

    console.log('payload:', payload)

    axios
      .put('/api/vacation-mode/edit', payload)
      .then((res) => {
        Swal.fire({
          icon: 'success',
          iconColor: '#41F2A0',
          title: '<span>Vacation mode successfully updated!</span>',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
      .catch((err) => {
        console.error(err)
        Swal.fire({
          icon: 'error',
          title:
            "<span>Oops! Something didn't work. Please try again or contact Ian for help.</span>",
          showConfirmButton: false,
          timer: 2500
        })
      })
  }

  return (
    <section className='edit-vacation-mode-section'>
      <form encType='multipart/form-data' className='edit-vacation-mode-form'>
        <h1>Set Vacation Mode</h1>

        <h3>Turn Vacation Mode On/Off</h3>
        <h6 className='admin-hint'>
          (This doesn't take effect until you save)
        </h6>

        {/* Begin checkbox */}
        <div className='edit-vacation-mode-checkbox-wrapper'>
          <div>
            <input
              type='checkbox'
              value='on'
              checked={vacationModeState?.vacationModeOn === 1}
              onClick={() => {
                setVacationModeState((prevState) => ({
                  ...prevState,
                  vacationModeOn: 1
                }))
              }}
            />
            <label className='vacation-mode-label'>ON</label>
          </div>
          <div>
            <input
              type='checkbox'
              value='off'
              checked={!vacationModeState?.vacationModeOn}
              onClick={() => {
                setVacationModeState((prevState) => ({
                  ...prevState,
                  vacationModeOn: 0
                }))
              }}
            />
            <label className='vacation-mode-label'>OFF</label>
          </div>
        </div>
        {/* End Checkbox */}

        <h3>Header</h3>
        <textarea
          {...vacationModeHeader}
          className='edit-vacation-mode-message-textarea'
          // defaultValue={vacationModeState?.header}
          placeholder='This text is BIG so it better be GOOD.'
        ></textarea>

        <h3>Body Message</h3>
        <textarea
          // inheriting vacationModeMessage object gives textarea access to value, setValue, and onChange
          {...vacationModeMessage}
          className='edit-vacation-mode-message-textarea'
          // defaultValue={vacationModeState?.message}
          placeholder="Let your customers know why the shop's closed and when it will be open again."
        ></textarea>

        <button className='submit-btn' onClick={(e: any) => handleSubmit(e)}>
          Save Vacation Mode Settings
        </button>
      </form>
    </section>
  )
}

// custom method for tracking the values of textareas
const useFormInput = (initialValue: string | undefined) => {
  const [value, setValue] = useState(initialValue)

  const handleChange = (e: any) => {
    e.preventDefault()

    setValue(e.target.value)
  }

  return {
    setValue,
    value,
    onChange: handleChange
  }
}

export default EditVacationModeComponent
