import React, { CSSProperties } from 'react'
import useViewportType from '../utils/useViewportType'
import { FONTS, COLORS, FONT_WEIGHTS, FONT_SIZES } from '../constants/theme'
import { useLocation } from 'react-router-dom'

interface Props {
  vacationMode: {
    vacationModeOn: 0 | 1
    header?: string
    message?: string
  }
}

const styles: { [key: string]: CSSProperties | any } = {
  wrapper: (isMobile: boolean, isHomePage: boolean) => ({
    width: '80%',
    margin: isHomePage ? 'auto' : '-50px auto 0 auto',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  textWrapper: (isMobile: boolean) => ({
    width: isMobile ? '100%' : '45%',
    marginTop: isMobile && '32px'
  }),
  header: {
    fontFamily: FONTS.serif,
    color: COLORS.darkBlue,
    fontSize: FONT_SIZES.desktopHeader,
    marginBottom: '24px'
  },
  message: {
    fontFamily: FONTS.sansSerif,
    color: COLORS.darkBlue,
    fontWeight: FONT_WEIGHTS.mediumLight,
    fontSize: FONT_SIZES.desktopBody,
    lineHeight: '32px'
  },
  imageWrapper: (isMobile: boolean) => ({
    width: isMobile ? '100%' : '45%',
    marginTop: isMobile ? '30px' : '0'
  }),
  image: {
    width: '100%'
  }
}

const VacationModePage = ({ vacationMode }: Props) => {
  const isMobile = useViewportType(900)

  const location = useLocation()
  const isHomePage = (location.pathname === '/' || location.pathname === '/home')

  return (
    <div style={styles.wrapper(isMobile, isHomePage)}>
      <div style={styles.textWrapper(isMobile)}>
        <h1 style={styles.header}>{vacationMode?.header}</h1>
        <h3 style={styles.message}>{vacationMode?.message}</h3>
      </div>
      <div style={styles.imageWrapper(isMobile)}>
        <img style={styles.image} src='../../assets/images/vacation_mode.svg' alt='A Car Speeding Away With A Feeble Tower of Luggage Lazily Strapped Up Top' />
      </div>
    </div>
  )
}

export default VacationModePage
