import React, { useEffect, useState } from 'react'
import PageWrapper from './components/common/PageWrapper'
import Home from './pages/Home'
import SingleProductPage from './pages/SingleProductPage'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import {
  Cart,
  Commissions,
  About,
  Contact,
  LoginPage,
  AdminPage
} from './src_deprecated/pages'
import Gallery from './pages/Gallery'
import Loading from './components/common/Loading'
import VacationModePage from './pages/VacationModePage'
import './App.css'
import SuccessfulOrder from './src_deprecated/pages/SuccessfulOrder'
import axios from 'axios'
import { setUserSession } from './utils/Session'
import { products } from './constants/products'

function App() {
  const [vacationMode, setVacationMode] = useState<{ vacationModeOn: 0 | 1, header: string, message: string }>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    getVacationMode()
  }, [])

  const getVacationMode = async (): Promise<void> => {
    await axios
      .get('/api/vacation-mode', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        setVacationMode(res.data)
        setLoading(false)
      })
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    // get random uuid4 for guest user activity and set it in localStorage
    const getGuestUserId = async (): Promise<void> => {
      const user = JSON.parse(sessionStorage.getItem('user') || '{}')

      if (!user?.id) {
        const res = await axios.post('/api/users/create', {
          name: 'guest',
          email: null,
          password: null,
          role: 'guest'
        })

        if (res.status === 200) {
          localStorage.setItem('id', res.data.user.id)
          setUserSession(res.data.token, res.data.user)
        } else {
          console.error(res.data)
        }
      } else {
        if (user?.id) {
          // refresh user session
          await axios.get(`/api/users/refresh/${user?.id}`)
        }
      }
    }
    getGuestUserId()
  }, [])

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {loading ? (
        <Loading />
      ) : (
        <PageWrapper>
          {!vacationMode?.vacationModeOn ? (
            <Switch>
              <Route exact path='/'>
                <Home />
              </Route>
              <Route exact path='/cart'>
                <Cart />
              </Route>
              <Route exact path='/print-gallery'>
                <Gallery productType='prints' />
              </Route>
              <Route exact path='/clothing-gallery'>
                <Gallery productType='clothing' />
              </Route>
              <Route exact path='/merch-gallery'>
                <Gallery productType='merch' />
              </Route>
              <Route exact path='/about'>
                <About />
              </Route>
              <Route exact path='/commissions'>
                <Commissions />
              </Route>
              <Route exact path='/contact'>
                <Contact />
              </Route>
              <Route exact path='/2af2ef2f-69f8-42db-867c-c167d625ff7e'>
                <LoginPage />
              </Route>
              <Route exact path='/image/:id'>
                <SingleProductPage productType={products.PRINTS} />
              </Route>
              <Route exact path='/clothing/:id'>
                <SingleProductPage productType={products.CLOTHING} />
              </Route>
              <Route exact path='/merch/:id'>
                <SingleProductPage productType={products.MERCH} />
              </Route>
              <Route exact path='/order-success'>
                <SuccessfulOrder />
              </Route>
              <Route exact path='/admin'>
                <AdminPage />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route exact path='/2af2ef2f-69f8-42db-867c-c167d625ff7e'>
                <LoginPage />
              </Route>
              <Route exact path='/admin'>
                <AdminPage />
              </Route>
              <Route exact path='/*'>
                <VacationModePage vacationMode={vacationMode} />
              </Route>
            </Switch>
          )}
        </PageWrapper>
      )}
    </BrowserRouter>
  )
}

export default App
